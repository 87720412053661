import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faFileImage, faImage, faBook, faCogs, faNewspaper } from "@fortawesome/free-solid-svg-icons";

const sidebar_eshops = [
  {
    "name": "Dati eshop",
    "namestate": "eshop-data",
    "icon": <FontAwesomeIcon icon={faEdit} />
  },
  {
    "name": "Note eshop",
    "namestate": "eshop-notes",
    "icon": <FontAwesomeIcon icon={faBook} />
  },
  {
    "name": "Immagine",
    "namestate": "eshop-image",
    "icon": <FontAwesomeIcon icon={faImage} />
  },
  {
    "name": "Thumb",
    "namestate": "eshop-thumb",
    "icon": <FontAwesomeIcon icon={faFileImage} />
  },
  {
    "name": "Recensioni eshop",
    "namestate": "eshop-reviews",
    "icon": <FontAwesomeIcon icon={faNewspaper} />
  },
  {
    "name": "Configurazione rating",
    "namestate": "eshop-rating-config",
    "icon": <FontAwesomeIcon icon={faCogs} />
  }
];


export default sidebar_eshops;