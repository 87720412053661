import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Container, Row, Col, Table, Form } from 'react-bootstrap';
import { Card, CardBody, CardTitle, CardTitleWrap } from '@/shared/components/Card';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from 'react-select';
import moment from 'moment';
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { debounce } from '@material-ui/core';
import { fetchReviewRatingModels } from '@/containers/Reviews/actions/fetchReviewRatingModels';
import { getEshopReviewsInfo } from "../actions/getEshopReviewsInfo";
import { getEshopRatingConfig } from "../actions/getEshopRatingConfig";
import { setEshopRatingConfig } from "../actions/setEshopRatingConfig";
import { successNotify, warningNotify } from "@/containers/App/Router";

const EshopRatingConfig = ({ item }) => {

  const dispatch = useDispatch();
  const [elem, setElem] = useState(item);
  const tableLabels = ['Modello rating', 'Numero recensioni', /*'Ultima recensione'*/];
  const [ratingModels, setRatingModels] = useState([]);
  const [ratingConfig, setRatingConfig] = useState('default');
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [inputValue, setInputValue] = useState('');
  const [actualRating, setActualRating] = useState(null);

  function formatDate(dateString) {
    return moment(dateString).format('DD/MM/YYYY')
  }

  const handleSelected = (option) => {
    setSelectedOption(option);
    setRatingConfig(option.value);
  };

  const handleSave = () => {
    const data = {
      eshop_id: elem.id,
      model_id: selectedOption.value,
    };
    dispatch(setEshopRatingConfig(data)).then((response) => {
      if (response.payload?.status === 'success') {
        successNotify();
        dispatch(getEshopRatingConfig({ id: item.id })).then((response) => {
          setActualRating(response.payload);
        });
      }
      else {
        warningNotify();
      }
    });
  }

  const handleChangeSelect = debounce((inputValue) => {
    setInputValue(inputValue);
  }, 500);

  useEffect(() => {
    if (inputValue.length >= 2) {
      const params = {
        title: inputValue
      };

      dispatch(fetchReviewRatingModels(params)).then((response) => {
        const items = response.payload.items.map((item) => {
          return { value: item.id, label: item.title };
        });
        setOptions(items);
      });
    }
  }, [inputValue, dispatch]);

  useEffect(() => {
    setSelectedOption({ value: actualRating?.model?.id, label: actualRating?.model?.title });
  }, [actualRating]);

  useEffect(() => {
    setElem(item);
    dispatch(getEshopRatingConfig({ id: item.id })).then((response) => {
      setActualRating(response.payload);
    });
    dispatch(getEshopReviewsInfo({ id: item.id })).then((response) => {
      const items = Object.entries(response.payload).map(([id, data]) => {
        return {
          value: id,
          model_name: data.model_name,
          count: data.count,
        };
      });
      setRatingModels(items);
    });
  }, [item, dispatch]);

  return (
    <Card>
      <CardBody>
        <CardTitleWrap>
          <CardTitle className="d-block">
            Configurazione valutazioni eshop
          </CardTitle>
        </CardTitleWrap>
        <Container>
          <Row>
            <Col xs={12} lg={6} className='my-3'>
              <h4 className="">
                Modello di rating: <b>{(actualRating && actualRating.model?.title) || 'default'} </b>
              </h4>
              {actualRating && actualRating.config && (
                <p>
                  <small>
                    ({'Ultimo aggiornamento: ' + formatDate(actualRating.config.update_date)})
                  </small>
                </p>
              )}
              <Form.Group controlId="registration_address_nation" className="mt-3">
                <Row>
                  <Form.Label>Modifica modello rating</Form.Label>
                  <Col xs={8}>
                    <Select
                      name="ratingConfig"
                      options={options}
                      value={selectedOption}
                      placeholder="Ricerca modello di rating"
                      onInputChange={handleChangeSelect}
                      onChange={(selectedOption) => handleSelected(selectedOption)}
                    />
                  </Col>
                  <Col xs={4}>
                    <Button variant="success" onClick={handleSave}>
                      <FontAwesomeIcon icon={faSave} className="me-1 pe-1 border-end" />
                      Salva
                    </Button>
                  </Col>
                </Row>
              </Form.Group>
            </Col>
            <Col xs={12} className="my-3">
              <h4>Storico recensioni:</h4>
              <Table striped bordered hover className="mt-3">
                <thead>
                  <tr>
                    {tableLabels.map((label, index) => {
                      return <th key={index}>{label}</th>
                    }
                    )}
                  </tr>
                </thead>
                <tbody>
                  {ratingModels && ratingModels.length > 0 ? ratingModels.map((item, index) => {
                    return (
                      <tr>
                        <td>{item.model_name}</td>
                        <td>
                          {item.count}
                        </td>
                      </tr>
                    )
                  }) : (
                    <tr>
                      <td colspan={tableLabels.length}> Nessun dato </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </CardBody>
    </Card >
  )
}

export default EshopRatingConfig
